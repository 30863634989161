// Colors
$white: #ffffff;
$gray: #3C3C3C;
$lightestGray: #F6F6F6;
$lightGray: #C3C3C3;
$lightPink: #FFB6B6;
$pink: #FF7474;
$lightGreen: #84E3C9;

// Sizes
$base-font-size: 16px;

$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$gutter: $grid-gutter-width;

// Fonts
$default-font: 'Open Sans';