@import "font-face";


@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}