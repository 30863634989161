@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

::placeholder {
  color: #555555;
}

::selection {
  background-color: $gray;
  color: $white;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.5;
  font-family: $default-font;
  font-weight: 300;
  overflow-x: hidden;
  color: #424242;
  background: $white;
  outline: 0;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(206, 217, 230, 1) 100%);
}


input,
textarea {
  outline: none;
  cursor: pointer;

  &:focus:required:invalid {
    border-color: red;
  }

  &:required:valid {
    border-color: green;
  }
}

a {
  cursor: pointer;

  &:hover {
    text-decoration: none;
    transition: color 0.3s ease;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;

  &.img-width {
    width: 100%;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

b,
strong {
  font-weight: bold;
}

input:focus {
  outline: 0;
  box-shadow: none;
}

.wrapper {
  flex-grow: 1;

  .content__headline {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .content__text {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .content__contact {
    font-size: 32px;

    span {
      display: block;
      font-size: 24px;
      font-weight: 700;
    }
  }
}


//Модули
@import "modules/modules";
@import "media";