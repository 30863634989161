/*==========  Desktop First  ==========*/

// ≤1200px
@include media-breakpoint-down(lg) {}

// ≤992px
@media (max-width: 992px) {}

@media (max-width: 800px) {}

// ≤768px
@media (max-width: 768px) {
  header.header {
    padding: 35px 0 35px;

    a.logotype {
      img {
        width: 75px;
      }
    }
  }

  .wrapper {

    .content__headline {
      font-size: 30px;
      margin-bottom: 15px;
    }

    .content__text {
      font-size: 18px;
      margin-bottom: 25px;
    }

    .content__contact {
      font-size: 26px;
      margin-bottom: 30px;

      span {
        font-size: 22px;
      }
    }
  }
}

// ≤576px
@include media-breakpoint-down(xs) {}

// ≤ 360px

@media (max-width: 360px) {}

/*==========  Mobile First  ==========*/

// ≥576px
@include media-breakpoint-up(sm) {}

// ≥768px
@media (min-width: 769px) {}

// ≥800px
@media (min-width: 768px) and (max-width: 1024px) {}

// ≥992px
@media (min-width: 993px) {}

// ≥1200px
@include media-breakpoint-up(xl) {}

@media (min-width: 1430px) {}